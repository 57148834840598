<template>
    <div>
        <div class="container">
            <div class="row justify-content-center text-center pt-0">
                <div class="col-12">
                    <div class="rooms row pt-3 pb-4">
                        <div v-for="product in singleRooms" :key="product.id" class="rooms__room col-12 col-md-6 py-3" @click="goToRoom(product)">
                            <room-component
                                :product="product"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import RoomComponent from "./rooms/RoomComponent";
    export default {
        name: "rooms",
        components: {RoomComponent},
        props: ['products'],
        data() {
            return {
                globalDiscountPercentage: window.globalDiscount.percentage
            }
        },
        computed: {
            singleRooms() {
                return this.products.filter(product => product.type === 1);
            },
            assetPath() {
                return process.env.ASSET_PATH || "";
            },
            enimg() {
                return this.assetPath + "/images/" + this.__('expedition-northpole/teaser.jpeg');
            },
            umimg() {
                return this.assetPath + "/images/" + this.__('underground-murder/teaser.png');
            },
            dtimg() {
                return this.assetPath + "/images/" + this.__('detention/teaser.png');
            }
        },
        methods: {
            goToRoom(product) {
                if(product.active) {
                    window.location.href = '/room/' + product.slug;
                }
            }
        }
    }
</script>

<style scoped>

</style>
